var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sheet-page sheet-page--frontpage"},[_c('div',[_c('div',{staticClass:"sheet-page__promo"},[_c('span',[_vm._v(_vm._s(_vm.translations.subHeading))]),_c('h1',[_vm._v(_vm._s(_vm.model.name))]),(_vm.model.description)?_c('p',[_vm._v(" "+_vm._s(_vm.model.description)+" ")]):_vm._e()]),_c('div',{staticClass:"sheet-page--frontpage__content"},[_c('div',[_c('div',{staticClass:"sheet-page--frontpage-media"},[_c('img',{attrs:{"src":_vm.selectedTheme.url,"alt":_vm.model.name,"height":"920","width":"878"}}),_c('div',{staticClass:"sheet-page--frontpage__info"},[_c('span',[_vm._v(_vm._s(_vm.translations.contentText))]),_c('div',[(_vm.items.businesscase?.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.items.businesscase.length)+" "+_vm._s(_vm.items.recipe.length > 1 ? _vm.translations.businesscasePluralLabel : _vm.translations.businesscaseSingularLabel)+" ")]):_vm._e(),(_vm.items.recipe?.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.items.recipe.length)+" "+_vm._s(_vm.items.recipe.length > 1 ? _vm.translations.recipePluralLabel : _vm.translations.recipeSingularLabel)+" ")]):_vm._e(),(_vm.items.product?.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.items.product.length)+" "+_vm._s(_vm.items.recipe.length > 1 ? _vm.translations.productPluralLabel : _vm.translations.productSingularLabel)+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"sheet-page__footer"},[_c('div',[_c('div',{staticClass:"sheet-page__footer-content"},[_c('div',{staticClass:"u-flex u-gap-spacing-xs u-flex-align-end"},[(_vm.model.showArlaProBranding)?_c('img',{attrs:{"src":_vm.iconArlapro,"alt":"Arla pro","width":"45","height":"45"}}):_vm._e(),_c('img',{attrs:{"src":_vm.iconArla,"width":"50","height":"30","alt":"Arla"}})]),_c('div',{class:[
              'sub-font',
              'u-text-align--center',
              'u-flex',
              'u-flex-direction-column',
              'u-flex-justify-space-between',
              'u-flex-align-self-stretch'
            ]},[_c('span',[_vm._v(" "+_vm._s(_vm.translations.createdDate)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.translations.footerUrl)+" ")])]),_c('div',{staticClass:"u-flex u-gap-spacing-xs u-flex-align-end"},[_c('div',{staticClass:"u-flex u-gap-spacing-xs u-flex-align-end"},[_c('span',{staticClass:"tag-font u-text-none-case u-text-align--right"},[_vm._v(" "+_vm._s(_vm.translations.qrCodeLabel)+" ")]),_c('span',{staticClass:"sheet-page__footer-logo-holder"},[_c('img',{attrs:{"src":_vm.iconQrCode,"alt":_vm.model.name,"width":"50","height":"50"}})])]),(_vm.model.showSustainableDietVisuals)?_c('span',{staticClass:"sheet-page__footer-logo-holder"},[_c('img',{attrs:{"src":_vm.iconGodaMal,"alt":"Goda Mal","width":"50","height":"50"}})]):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }