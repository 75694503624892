<template>
  <div class="sheet-page sheet-page--last-page">
    <div>
      <div class="sheet-page__main">
        <img
          class="sheet-page__main-image"
          :src="translations.lastPageImageUrl"
          alt="Image"
          height="545"
          width="935"
        >
        <div class="sheet-page__content">
          <div class="sheet-page__contact-container">
            <div>
              <h3>{{ model.relatedContentTitle || translations.lastPageColumn1Title }}</h3>
              <p v-if="model.relatedContentDescription">
                {{ model.relatedContentDescription }}
              </p>
              <div
                v-else
                v-html="translations.lastPageColumn1Text"
              />
              <div
                v-if="translations.qrCodeBase64"
                class="qr-code--extended mt-xxs"
              >
                <img
                  :src="`data:image/png;base64,${translations.qrCodeBase64}`"
                  :alt="translations.url"
                  width="100"
                  height="100"
                >
                <div>
                  <strong
                    v-if="translations.lastPageQrCodeTitle"
                    class="u-flex"
                  >
                    {{ translations.lastPageQrCodeTitle }}
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <h3>{{ translations.lastPageColumn2Title }}</h3>
              <div v-html="translations.lastPageColumn2Text" />
              <address
                v-for="contactInfo in translations.lastPageColumn2Contacts"
                :key="contactInfo.title"
              >
                <strong>{{ contactInfo.title }}</strong>
                <span>{{ contactInfo.email }}</span>
                <span>{{ contactInfo.phone }}</span>
              </address>

              <address
                v-if="model.contactInfoName || model.contactInfoEmail || model.contactInfoPhone"
              >
                <strong v-if="model.contactInfoName">{{ model.contactInfoName }}</strong>
                <span v-if="model.contactInfoEmail">{{ model.contactInfoEmail }}</span>
                <span v-if="model.contactInfoPhone">{{ model.contactInfoPhone }}</span>
              </address>

              <div class="sheet-page__social-media">
                {{ translations.url }}
                <ul
                  v-if="translations.socialMediaLinks?.length"
                  class="social-media-list"
                >
                  <li
                    v-for="link in translations.socialMediaLinks"
                    :key="link.text"
                    class="social-media-list__item"
                  >
                    <a
                      :href="link.url"
                      :class="[`icon-${link.text.toLowerCase()}--dark`]"
                      class="social-media-list__link"
                    >
                      <span class="u-hide-on-screenonly">{{ link.text }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sheet-page__footer">
        <div>
          <div class="sheet-page__info" />
          <div class="sheet-page__footer-content">
            <img
              :src="iconArla"
              width="50"
              height="30"
              alt="Arla"
            >
            <span class="u-text-align--center">
              {{ translations.url }}
            </span>
            <span>
              Sida 1 of 1
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCatalogue from '@/compositions/useCatalogue'
import {
  iconArla
} from '@/helpers/icons'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    const {
      lastPage: translations
    } = useCatalogue()

    return {
      translations,
      iconArla
    }
  }
}
</script>
