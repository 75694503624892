import { localize } from 'vee-validate-success'
import sv from 'vee-validate-success/dist/locale/sv.json'

// Install and activate the Sweden locale.
localize('sv', sv)

const phone = {
  validate: (value) => value.match(/^[0-9]{9,12}$/) || 'Telefonnummer ska innehålla 9-12 siffror.'
}

// eslint-disable-next-line import/prefer-default-export
export { phone }
