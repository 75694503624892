<template>
  <div class="sheet-page sheet-page--frontpage">
    <div>
      <div class="sheet-page__promo">
        <span>{{ translations.subHeading }}</span>
        <h1>{{ model.name }}</h1>
        <p v-if="model.description">
          {{ model.description }}
        </p>
      </div>
      <div class="sheet-page--frontpage__content">
        <div>
          <div class="sheet-page--frontpage-media">
            <img
              :src="selectedTheme.url"
              :alt="model.name"
              height="920"
              width="878"
            >
            <div class="sheet-page--frontpage__info">
              <span>{{ translations.contentText }}</span>
              <div>
                <span v-if="items.businesscase?.length > 0">
                  {{ items.businesscase.length }}
                  {{ items.recipe.length > 1
                    ? translations.businesscasePluralLabel
                    : translations.businesscaseSingularLabel
                  }}
                </span>
                <span v-if="items.recipe?.length > 0">
                  {{ items.recipe.length }}
                  {{ items.recipe.length > 1
                    ? translations.recipePluralLabel
                    : translations.recipeSingularLabel
                  }}
                </span>
                <span v-if="items.product?.length > 0">
                  {{ items.product.length }}
                  {{ items.recipe.length > 1
                    ? translations.productPluralLabel
                    : translations.productSingularLabel
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sheet-page__footer">
        <div>
          <div class="sheet-page__footer-content">
            <div class="u-flex u-gap-spacing-xs u-flex-align-end">
              <img
                v-if="model.showArlaProBranding"
                :src="iconArlapro"
                alt="Arla pro"
                width="45"
                height="45"
              >
              <img
                :src="iconArla"
                width="50"
                height="30"
                alt="Arla"
              >
            </div>
            <div
              :class="[
                'sub-font',
                'u-text-align--center',
                'u-flex',
                'u-flex-direction-column',
                'u-flex-justify-space-between',
                'u-flex-align-self-stretch'
              ]"
            >
              <span>
                {{ translations.createdDate }}
              </span>
              <span>
                {{ translations.footerUrl }}
              </span>
            </div>
            <div class="u-flex u-gap-spacing-xs u-flex-align-end">
              <div class="u-flex u-gap-spacing-xs u-flex-align-end">
                <span class="tag-font u-text-none-case u-text-align--right">
                  {{ translations.qrCodeLabel }}
                </span>
                <span class="sheet-page__footer-logo-holder">
                  <img
                    :src="iconQrCode"
                    :alt="model.name"
                    width="50"
                    height="50"
                  >
                </span>
              </div>
              <span
                v-if="model.showSustainableDietVisuals"
                class="sheet-page__footer-logo-holder"
              >
                <img
                  :src="iconGodaMal"
                  alt="Goda Mal"
                  width="50"
                  height="50"
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import useCatalogue from '@/compositions/useCatalogue'
import {
  iconArla,
  iconArlapro,
  iconGodaMal,
  iconQrCode
} from '@/helpers/icons'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { model } = props
    const {
      firstPage: translations,
      items,
      themes
    } = useCatalogue()

    const selectedTheme = computed(() => themes.value.find((theme) => theme.value === model.theme))

    return {
      selectedTheme,
      items,
      translations,
      iconGodaMal,
      iconArlapro,
      iconQrCode,
      iconArla
    }
  }
}
</script>
