const moveRangeNumber = (input, output) => {
  const outputWidth = output.clientWidth
  let newPlace

  // Measure width of range input
  const width = input.clientWidth - outputWidth
  const min = parseInt(input.min, 10)
  const max = parseInt(input.max, 10)
  const { value } = input

  // Figure out placement percentage between left and right of input
  const progress = (value - min) / (max - min)

  // Prevent bubble from going beyond left or right (unsupported browsers)
  if (progress < 0) {
    newPlace = 0
  } else if (progress > 1) {
    newPlace = width
  } else {
    newPlace = width * progress
  }

  input.style.background = `linear-gradient(to right, #19A413 ${progress * 100}%, transparent ${progress * 100}%)`
  output.style.left = `${newPlace}px`
  output.innerHTML = value
}

const rangeInputChange = (
  container = document,
  parentSelector = '.FormRange',
  inputSelector = '.FormRange__Input',
  outputSelector = '.FormRange__Output'
) => {
  const rangeElements = container.querySelectorAll(parentSelector)

  rangeElements.forEach((element) => {
    const input = element.querySelector(inputSelector)
    const output = element.querySelector(outputSelector)
    if (!output || !input) {
      return
    }

    output.classList.add('is-visible')
    moveRangeNumber(input, output)
    input.addEventListener('input', () => {
      moveRangeNumber(input, output)
    })
  })
}

export default rangeInputChange
