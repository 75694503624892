import { hem } from '@/helpers/hem'
import inputRange from '@/helpers/input-range'
import GtmHelper from '@/helpers/GtmHelper'

window.newsletterSignupGeneralTracking = () => {
  GtmHelper.newsletterSignupGeneral(hem.value, 'download report')
}

const trackContactForm = ($form) => {
  if (!$form.length) return

  const formFieldsInfo = window.epi?.EPiServer?.Forms[$form.attr('id')].ElementsInfo
  const questionTypeName = Object.entries(formFieldsInfo).find(([, value]) => value.friendlyName === 'questionTypeDropdown')[0] || null
  const $questionTypeField = $form.find(`[name="${questionTypeName}"]`)
  let questionType = null

  if (!$questionTypeField.length) return

  $questionTypeField.on('change', () => {
    questionType = $questionTypeField.val()
    GtmHelper.pushDataLayer({
      event: 'questionTypeSelect',
      questionType
    })
  })

  $form
    .on('formsSubmitted', () => {
      GtmHelper.pushDataLayer({
        event: 'contactFormSubmit',
        questionType
      })
    })
}

export default function () {
  this.initialize = function () {
    inputRange()
    this.fileUploadEvent()

    if (typeof $$epiforms !== 'undefined') {
      const forms = $$epiforms('.EPiServerForms')

      this.adjustDateTimePicker()
      trackContactForm(forms.filter('[data-f-metadata="contactForm"]'))

      forms
        .on('formsSubmitted formsSubmittedError', (e) => {
          const formElement = e.currentTarget
          const formOffsetTop = window.pageYOffset + formElement.getBoundingClientRect().top - 150
          window.scrollTo(0, formOffsetTop)
        })
        .on('formsSubmitted', (e) => {
          const formElement = e.currentTarget
          // we need temp div to decode html entities that the epi returns
          const tempElement = document.createElement('div')
          tempElement.innerHTML = window.epi?.EPiServer?.Forms[formElement.id]?.Name
          const formNameFallback = tempElement.textContent
          const formName = !formElement.dataset?.fMetadata
            ? formNameFallback
            : formElement.dataset?.fMetadata

          if (formName) {
            GtmHelper.pushDataLayer({
              event: 'formSubmit',
              formName,
              sfmcHem: hem.value
            })
          }
        })
        .filter('[data-f-metadata="signup"]')
        .on('formsSubmitted', () => {
          GtmHelper.pushDataLayer({
            event: 'newsletterSignupBecomeCustomer',
            sfmcHem: hem.value
          })
        })
        .on('formsSubmittedError', () => {
          GtmHelper.pushDataLayer({
            event: 'becomeCustomerFailed',
            sfmcHem: hem.value
          })
        })
    }
  }

  this.adjustDateTimePicker = () => {
    if ($$epiforms.datetimepicker) {
      $$epiforms.datetimepicker._defaults.onSelect = () => {
        $$epiforms.datetimepicker._hideDatepicker()
      }
    }
  }

  this.fileUploadEvent = function () {
    $('.FormFileUpload').on('change', '.FormFileUpload__Input', (e) => {
      const fileContainer = e.delegateTarget
      const fileName = e.currentTarget.value.split('\\').pop()
      const postedFile = fileContainer.querySelector('.FormFileUpload__PostedFile')
      if (postedFile && fileName) {
        postedFile.innerHTML = `<span>Du har valt att ladda upp:</span> ${fileName}`
        fileContainer.classList.add('has-file')
      } else {
        postedFile.innerHTML = ''
        fileContainer.classList.remove('has-file')
      }
    })
  }
}
