<template>
  <div>
    <div class="form-row">
      <strong class="h4">
        <em>{{ currentStepNumber }}/{{ totalSteps }}</em>
        <br>
        {{ catalogueTranslations.modalCatalogueBasicDetailsHeading }}
      </strong>
    </div>
    <div class="form-row">
      <label
        for="catalogue-name"
        class="form-label"
      >
        {{ catalogueTranslations.modalCatalogueBasicDetailsNameHeading }} (*)
      </label>
      <input
        id="catalogue-name"
        v-model="value.name"
        :placeholder="catalogueTranslations.modalCatalogueBasicDetailsNamePlaceholder"
        type="text"
        required
      >
    </div>
    <div class="form-row">
      <label
        for="catalogue-description"
        class="form-label"
      >
        {{ catalogueTranslations.modalCatalogueBasicDetailsDescriptionHeading }}
      </label>
      <textarea
        id="catalogue-description"
        v-model="value.description"
        cols="5"
        rows="5"
        :placeholder="catalogueTranslations.modalCatalogueBasicDetailsDescriptionPlaceholder"
        @input.once="onStartTyping('description')"
      />
    </div>
  </div>
</template>

<script>
import useCatalogue from '@/compositions/useCatalogue'
import gtmHelper from '@/helpers/GtmHelper'
import useWizardFlow from '@/compositions/catalogue/useWizardFlow'

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  setup() {
    const {
      state: {
        totalSteps
      },
      currentStepNumber,
      currentStep
    } = useWizardFlow()
    const { catalogueTranslations } = useCatalogue()

    const onStartTyping = (fieldName) => {
      gtmHelper.catalogueFieldInput(fieldName)
    }

    const onFieldChange = (fieldName, value) => {
      gtmHelper.catalogueFieldInput(fieldName, value)
    }

    return {
      catalogueTranslations,
      currentStepNumber,
      currentStep,
      totalSteps,

      onStartTyping,
      onFieldChange
    }
  }
}
</script>
