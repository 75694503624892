import { render, staticRenderFns } from "./catalogue-modal.vue?vue&type=template&id=0adc02a2&"
import script from "./catalogue-modal.vue?vue&type=script&lang=js&"
export * from "./catalogue-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports