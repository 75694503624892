<template>
  <div>
    <div class="form-row">
      <strong class="h4">
        <em>{{ currentStepNumber }}/{{ totalSteps }}</em>
        <br>
        {{ catalogueTranslations.modalCatalogueVisualDetailsHeading }}
      </strong>
    </div>
    <div class="form-row">
      <label
        for="frontpage-image-field"
        class="form-label"
      >
        {{ catalogueTranslations.modalCatalogueVisualDetailsThemesHeading }}
      </label>
      <select
        id="frontpage-image-field"
        v-model="value.theme"
        name="frontPageImage"
        required
        class="form-dropdown"
        @change="onFieldChange($event.target.name, $event.target.value)"
      >
        <option
          v-for="item in themes"
          :key="item.value"
          :value="item.value"
          v-text="item.text"
        />
      </select>
    </div>
    <div class="form-row">
      <span class="form-row__caption">
        {{ catalogueTranslations.modalCatalogueVisualDetailsThemeColorsHeading }}
      </span>
      <ThemePicker
        v-model="value.color"
        :themes="colors"
        theme-prefix="catalogue-"
        required
        @change="onFieldChange"
      />
    </div>
    <div class="form-row form-row--choice form-row--radio">
      <span class="form-row__caption">
        {{ catalogueTranslations.modalCatalogueVisualDetailsArlaProBrandingText }}
      </span>
      <label class="form-row--choice__item">
        <input
          v-model="value.showArlaProBranding"
          type="radio"
          name="arlaProBranding"
          :value="true"
          class="form-radio"
          @change="onFieldChange($event.target.name, $event.target.value)"
        >
        <span class="form-choice__label">
          {{ catalogueTranslations.modalCatalogueVisualDetailsYesText }}
        </span>
        <span class="form-choice__indicator" />
      </label>
      <label class="form-row--choice__item">
        <input
          v-model="value.showArlaProBranding"
          type="radio"
          name="arlaProBranding"
          :value="false"
          class="form-radio"
          @change="onFieldChange($event.target.name, $event.target.value)"
        >
        <span class="form-choice__label">
          {{ catalogueTranslations.modalCatalogueVisualDetailsNoText }}
        </span>
        <span class="form-choice__indicator" />
      </label>
    </div>
    <div class="form-row form-row--choice form-row--radio">
      <div class="form-row__caption">
        <div class="tooltip-holder">
          <Tooltip
            :model="{
              title: catalogueTranslations.modalCatalogueVisualDetailsNutritionDataText,
              content: catalogueTranslations.modalCatalogueVisualDetailsNutritionDataTextTooltip
            }"
            placement="top"
          />
        </div>
      </div>
      <label class="form-row--choice__item">
        <input
          v-model="value.showRecipeNutritionData"
          type="radio"
          name="recipeNutritionalData"
          :value="true"
          class="form-radio"
          @change="onFieldChange($event.target.name, $event.target.value)"
        >
        <span class="form-choice__label">
          {{ catalogueTranslations.modalCatalogueVisualDetailsYesText }}
        </span>
        <span class="form-choice__indicator" />
      </label>
      <label class="form-row--choice__item">
        <input
          v-model="value.showRecipeNutritionData"
          type="radio"
          name="recipeNutritionalData"
          :value="false"
          class="form-radio"
          @change="onFieldChange($event.target.name, $event.target.value)"
        >
        <span class="form-choice__label">
          {{ catalogueTranslations.modalCatalogueVisualDetailsNoText }}
        </span>
        <span class="form-choice__indicator" />
      </label>
    </div>
    <div class="form-row form-row--choice form-row--radio">
      <span class="form-row__caption">
        {{ catalogueTranslations.modalCatalogueVisualDetailsSustainableDietText }}
      </span>
      <label class="form-row--choice__item">
        <input
          v-model="value.showSustainableDietVisuals"
          type="radio"
          name="sustainableDietVisuals"
          :value="true"
          class="form-radio"
          @change="onFieldChange($event.target.name, $event.target.value)"
        >
        <span class="form-choice__label">
          {{ catalogueTranslations.modalCatalogueVisualDetailsYesText }}
        </span>
        <span class="form-choice__indicator" />
      </label>
      <label class="form-row--choice__item">
        <input
          v-model="value.showSustainableDietVisuals"
          type="radio"
          name="sustainableDietVisuals"
          :value="false"
          class="form-radio"
          @change="onFieldChange($event.target.name, $event.target.value)"
        >
        <span class="form-choice__label">
          {{ catalogueTranslations.modalCatalogueVisualDetailsNoText }}
        </span>
        <span class="form-choice__indicator" />
      </label>
    </div>
  </div>
</template>

<script>
import useCatalogue from '@/compositions/useCatalogue'
import gtmHelper from '@/helpers/GtmHelper'
import ThemePicker from '@/components/formElements/theme-picker.vue'
import useWizardFlow from '@/compositions/catalogue/useWizardFlow'
import Tooltip from '@/components/tooltip.vue'

export default {
  components: {
    Tooltip,
    ThemePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  setup() {
    const {
      state: {
        totalSteps
      },
      currentStepNumber,
      currentStep
    } = useWizardFlow()
    const {
      catalogueTranslations,
      themes,
      colors
    } = useCatalogue()

    const onFieldChange = (fieldName, value) => {
      gtmHelper.catalogueFieldInput(fieldName, value)
    }

    return {
      catalogueTranslations,
      currentStepNumber,
      currentStep,
      totalSteps,
      themes,
      colors,

      onFieldChange
    }
  }
}
</script>
