<template>
  <div
    class="catalogue-form-preview"
    tabindex="-1"
  >
    <strong>{{ catalogueTranslations[currentStep.previewTitle] }}</strong>
    <div>
      <div
        :class="[`u-theme--catalogue-${model.color}`]"
        class="catalogue-sheet--default catalogue-sheet"
      >
        <component
          :is="currentStep.preview"
          :model="model"
        />
      </div>
    </div>
    <button
      class="catalogue-form-preview__back cta--clear"
      type="button"
      @click="$emit('close')"
    >
      <IconArrow class="icon" />
      <span class="visuallyhidden">Tillbaka</span>
    </button>
  </div>
</template>

<script>
import CatalogueFirstPage from '@/components/catalogue/previewPages/catalogue-first-page.vue'
import CatalogueLastPage from '@/components/catalogue/previewPages/catalogue-last-page.vue'
import IconArrow from '@/assets/icons/arrow-right.svg'
import useCatalogue from '@/compositions/useCatalogue'
import useWizardFlow from '@/compositions/catalogue/useWizardFlow'

export default {
  components: {
    CatalogueFirstPage,
    CatalogueLastPage,
    IconArrow
  },
  setup() {
    const {
      currentStep
    } = useWizardFlow()

    const {
      formData: model,
      catalogueTranslations
    } = useCatalogue()

    return {
      model,
      currentStep,
      catalogueTranslations
    }
  }
}
</script>
