<template>
  <div>
    <div class="form--default c-header-sidebar-section">
      <div class="form-title">
        <strong class="h3">
          {{ translations.login }}
        </strong>
      </div>
      <div class="u-flex u-flex-align-center u-flex-direction-column u-gap-sm mb-sm">
        <a
          :href="connectLoginUrl"
          class="cta cta--average u-text-nowrap u-width-100"
          @click="trackLoginConnect"
        >
          {{ translations.arlaConnectLoginText }}
        </a>
        <a
          :href="translations.becomeCustomerLinkUrl"
          class="u-link--underline"
          v-text="translations.becomeCustomerLinkText"
        />
      </div>
    </div>
    <hr>
    <div class="form--default c-header-sidebar-section">
      <button
        class="cta cta--link cta--average"
        @click="onChooseLoginType(TYPES.private)"
      >
        <span v-text="translations.loginToPrivateAccountLabel" />
        <IconArrow class="icon ml-xxs" />
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import IconArrow from '@/assets/icons/arrow-right.svg'
import GtmHelper from '@/helpers/GtmHelper'
import useLogin, { TYPES } from '@/compositions/useLogin'
import useHeaderControls from '@/compositions/useHeaderControls'

export default {
  components: {
    IconArrow
  },
  props: {
    translations: {
      type: Object,
      required: true
    }
  },
  setup() {
    const {
      setCurrentStep
    } = useLogin()

    const {
      redirectToEcom
    } = useHeaderControls()
    const connectLoginUrl = computed(() => `/authentication/login/?redirectToEcom=${redirectToEcom.value}`)

    const onChooseLoginType = (type) => {
      GtmHelper.logInChooseType(type)
      setCurrentStep(type)
    }

    const trackOldLoginClick = (type) => {
      GtmHelper.logInChooseType(type)
    }

    const trackLoginConnect = () => {
      GtmHelper.loginConnect()
    }

    return {
      TYPES,
      connectLoginUrl,

      onChooseLoginType,
      trackOldLoginClick,
      trackLoginConnect
    }
  }
}
</script>
