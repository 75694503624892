export default {
  basicDetails: {
    component: 'CatalogueBasicDetails',
    preview: 'CatalogueFirstPage',
    previewTitle: 'modalCatalogueFirstPagePreviewHeading',
    isFinal: false
  },
  visualDetails: {
    component: 'CatalogueVisualDetails',
    preview: 'CatalogueFirstPage',
    previewTitle: 'modalCatalogueFirstPagePreviewHeading',
    isFinal: false
  },
  additionalInformation: {
    component: 'CatalogueAdditionalInformation',
    preview: 'CatalogueLastPage',
    previewTitle: 'modalCatalogueLastPagePreviewHeading',
    isFinal: true
  }
}
