import { hem } from '@/helpers/hem'
import inputRange from '@/helpers/input-range'
import GtmHelper from '@/helpers/GtmHelper'

window.newsletterSignupGeneralTracking = () => {
  GtmHelper.newsletterSignupGeneral(hem.value, 'download report')
}

export default function () {
  this.initialize = function () {
    inputRange()
    this.fileUploadEvent()

    if (typeof $$epiforms !== 'undefined') {
      this.adjustDateTimePicker()
      $$epiforms('.EPiServerForms')
        .on('formsSubmitted formsSubmittedError', (e) => {
          const formElement = e.currentTarget
          const formOffsetTop = window.pageYOffset + formElement.getBoundingClientRect().top - 150
          window.scrollTo(0, formOffsetTop)
        })
        .on('formsSubmitted', (e) => {
          const formElement = e.currentTarget
          // we need temp div to decode html entities that the epi returns
          const tempElement = document.createElement('div')
          tempElement.innerHTML = window.epi?.EPiServer?.Forms[formElement.id]?.Name
          const formNameFallback = tempElement.textContent
          const formName = !formElement.dataset?.fMetadata
            ? formNameFallback
            : formElement.dataset?.fMetadata

          if (formName) {
            GtmHelper.pushDataLayer({
              event: 'formSubmit',
              formName,
              sfmcHem: hem.value
            })
          }
        })
        .filter('[data-f-metadata="signup"]')
        .on('formsSubmitted', () => {
          GtmHelper.pushDataLayer({
            event: 'newsletterSignupBecomeCustomer',
            sfmcHem: hem.value
          })
        })
        .on('formsSubmittedError', () => {
          GtmHelper.pushDataLayer({
            event: 'becomeCustomerFailed',
            sfmcHem: hem.value
          })
        })
    }
  }

  this.adjustDateTimePicker = () => {
    if ($$epiforms.datetimepicker) {
      $$epiforms.datetimepicker._defaults.onSelect = () => {
        $$epiforms.datetimepicker._hideDatepicker()
      }
    }
  }

  this.fileUploadEvent = function () {
    $('.FormFileUpload').on('change', '.FormFileUpload__Input', (e) => {
      const fileContainer = e.delegateTarget
      const fileName = e.currentTarget.value.split('\\').pop()
      const postedFile = fileContainer.querySelector('.FormFileUpload__PostedFile')
      if (postedFile && fileName) {
        postedFile.innerHTML = `<span>Du har valt att ladda upp:</span> ${fileName}`
        fileContainer.classList.add('has-file')
      } else {
        postedFile.innerHTML = ''
        fileContainer.classList.remove('has-file')
      }
    })
  }
}
